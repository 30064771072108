import { WorkflowExecutionsFilters } from "../../../api";
import { User } from "../../../api/types";
import { IFilter } from "../WorkflowExecutionsActionBar";
import { ApprovalStatusEnum } from "./types";

export interface CAWorkflowExecutionFilters extends WorkflowExecutionsFilters {
  "search_attributes.current_approver.id": string[];
  "search_attributes.approval_status": string[];
}

type FilterGeneratorMap = {
  "search_attributes.current_approver.id": User[];
  "search_attributes.approval_status": null;
  "search_attributes.approvers[*].user.id": User[];
  // Add other filter keys and their corresponding optionsSource types here
};

export const filterGenerators: {
  [K in keyof FilterGeneratorMap]: (optionsSource: FilterGeneratorMap[K]) => IFilter;
} = {
  "search_attributes.current_approver.id": (optionsSource: User[]) => {
    return {
      id: "search_attributes.current_approver.id",
      type: "multiselect",
      selectorType: "multi",
      wordSeparator: "is",
      label: "Current Approver",
      options: optionsSource
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((user) => ({ label: user.id, value: user.id })),
    };
  },
  "search_attributes.approval_status": () => {
    return {
      id: "search_attributes.approval_status",
      type: "multiselect",
      selectorType: "multi",
      wordSeparator: "is",
      label: "Approval Status",
      options: Object.entries(ApprovalStatusEnum).map(([key, value]) => ({
        label: key,
        value: value,
      })),
    };
  },
  "search_attributes.approvers[*].user.id": (optionsSource: User[]) => {
    return {
      id: "search_attributes.approvers[*].user.id",
      type: "multiselect",
      selectorType: "multi",
      wordSeparator: "include",
      label: "Approvers",
      options: optionsSource
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((user) => ({ label: user.id, value: user.id })),
    };
  },

  // Add other filter generators here
};
