import { DateRange, Query, ResultSet } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { format, startOfWeek } from "date-fns";
import { measures } from "../../api/cube";

export const TABLE_ROW_INDEX = {
  FORECAST: 0,
  DEMAND_PLAN: 1,
  MODEL: 2,
  PLAN: 3,
};

export const TABLE_ROW_LABELS = {
  [TABLE_ROW_INDEX.FORECAST.toString()]: "Cust (Total)",
  [TABLE_ROW_INDEX.DEMAND_PLAN.toString()]: "Cust (Non-SO)",
  [TABLE_ROW_INDEX.MODEL.toString()]: "Model",
  [TABLE_ROW_INDEX.PLAN.toString()]: "Plan",
};

const mapTableDataFromSet = (resultSet: ResultSet) => {
  // Initialize table data with empty rows (len = max(table_row_index + 1))
  // Fill based on table row labels
  const tableData: Record<string, string | number | boolean>[] = Array(Math.max(...Object.values(TABLE_ROW_INDEX)) + 1)
    .fill(null)
    .map((_, i) => ({ start: TABLE_ROW_LABELS[i.toString()] }));

  // Create key for each week in each row
  resultSet?.tablePivot().forEach((elem) => {
    const week = format(
      startOfWeek(new Date(elem["demand_forecast_most_recent.week"] as string), { weekStartsOn: 1 }),
      "yyyy-MM-dd",
    );
    const recommended = elem[measures.forecast.mostRecent.recommended];
    const recentForecast = elem[measures.forecast.mostRecent.total];
    const recentDemandPlan = elem[measures.forecast.mostRecent.totalDemandPlan];
    let fixedTotal = elem[measures.forecast.mostRecent.fixed];
    if (fixedTotal === 0) {
      fixedTotal = +recommended;
    }
    tableData[TABLE_ROW_INDEX.FORECAST][week] = recentForecast;
    tableData[TABLE_ROW_INDEX.DEMAND_PLAN][week] = recentDemandPlan;
    tableData[TABLE_ROW_INDEX.MODEL][week] = recommended;
    tableData[TABLE_ROW_INDEX.PLAN][week] = fixedTotal;
  });

  return tableData;
};

export const useFetchForecastTableData = (itemId: string | number, dateRange: DateRange) => {
  const query: Query = {
    measures: [
      measures.forecast.mostRecent.total,
      measures.forecast.mostRecent.totalDemandPlan,
      measures.forecast.mostRecent.recommended,
      measures.forecast.mostRecent.fixed,
    ],
    timeDimensions: [
      {
        dimension: "demand_forecast_most_recent.week",
        granularity: "week",
        dateRange,
      },
    ],
    order: {
      "demand_forecast_most_recent.week": "asc",
    },
    dimensions: [
      "demand_forecast_most_recent.company_id",
      "demand_forecast_most_recent.item_id",
      "demand_forecast_most_recent.week",
    ],
    filters: [
      {
        member: "demand_forecast_most_recent.item_id",
        operator: "equals",
        values: [itemId.toString()],
      },
    ],
  };

  const { resultSet, ...cube } = useCubeQuery(query);
  const tableData = mapTableDataFromSet(resultSet!);

  return { tableData, ...cube };
};
