import { DateRange, TimeDimensionGranularity } from "@cubejs-client/core";
import { ColDef, GridOptions } from "ag-grid-community";
import {
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  eachWeekOfInterval,
  format,
  isMonday,
  isValid,
  startOfMonth,
  startOfWeek,
} from "date-fns";

export const ITEM_FCST_PAGE_TS_COL_QTY = 30;
export const ITEM_FCST_PAGE_TS_MIN_WEEK_NUM = 1;
const DNS_FNS_MONDAY = 1;

export enum StandardDateFormat {
  SHORT_HEADER = "MM-dd",
  ISO_DATEONLY = "yyyy-MM-dd",
  FULL = "MMMM dd, yyyy",
  FULLSHORTMONTH = "MMM dd, yyyy",
  MONTHDAY = "MMM dd",
  MONTH = "MMM",
  MONTHYEAR = "MMM, yyyy",
}

// Python strftime equivalent (for AGCharts)
export enum StandardDateFormatStrf {
  FULL = "%b %d, %Y",
  MONTHDAY = "%b %e",
  MONTHYEAR = "%b %Y",
}

export const STD_GRID_OPTIONS: GridOptions = {
  tooltipShowDelay: 250,
  tooltipInteraction: true,
};

export const defaultGridRichSelectParams = {
  allowTyping: true,
  filterList: true,
  highlightMatch: true,
  valueListMaxHeight: 220,
  valueListMaxWidth: 140,
};

export enum SupportedGranularityOptions {
  week = "week",
  month = "month",
}

export const getHeaderOptionsForTimeSeriesCol = (relWeekNum: number, weekDate: Date) => {
  const relDayNum = differenceInDays(weekDate, new Date());
  const colDef: ColDef = {
    headerName: format(weekDate, StandardDateFormat.SHORT_HEADER),
    headerTooltip: format(weekDate, StandardDateFormat.FULL) + ` (+${relWeekNum}W, +${relDayNum}D)`,
  };
  return colDef;
};

export const getNextMondayWeekStartFromToday = () => {
  // First monday after today is start point
  const currentDate = new Date();
  if (isMonday(currentDate)) {
    // Bump forward 7 days to anchor in next week
    addWeeks(currentDate, 1);
  }
  return startOfWeek(currentDate, { weekStartsOn: DNS_FNS_MONDAY });
};

export const getNextMonthStartFromToday = () => {
  // First monday after today is start point
  const currentDate = new Date();
  return addMonths(startOfMonth(currentDate), 1);
};

export const buildDateRangeOutwardFromNextPeriodStart = (
  numPeriodsBack: number,
  numPeriodsForward: number,
  granularity: SupportedGranularityOptions,
): [Date, Date] => {
  if (granularity == SupportedGranularityOptions.week) {
    const centerAnchor = getNextMondayWeekStartFromToday();
    return [addWeeks(centerAnchor, -numPeriodsBack), addWeeks(centerAnchor, numPeriodsForward)];
  } else if (granularity == SupportedGranularityOptions.month) {
    const centerAnchor = getNextMonthStartFromToday();
    return [addMonths(centerAnchor, -numPeriodsBack), addMonths(centerAnchor, numPeriodsForward)];
  } else {
    throw Error("Unsupported granularity option");
  }
};

export const getTSColWeekMinMax = (weekNumMin: number, weekColQty: number) => {
  const week1 = getNextMondayWeekStartFromToday();
  const weekNumMax = weekNumMin + weekColQty;
  const weekMin = addWeeks(week1, weekNumMin);
  const weekMax = addWeeks(week1, weekNumMax);
  return {
    start: {
      date: weekMin,
      num: weekNumMin,
    },
    end: {
      date: weekMax,
      num: weekNumMax,
    },
  };
};

export const dateToCubeQueryDateString = (date: Date) => {
  // Strips down to yyyy-mm-dd to avoid TZ blocking return of "weeks" already date_trunced
  return date.toISOString().substring(0, 10);
};

export const getDateRangeForCubeQuery = (weekNumMin: number, weekColQty: number) => {
  const weekMinMax = getTSColWeekMinMax(weekNumMin, weekColQty);
  const dateRange: DateRange = [
    dateToCubeQueryDateString(weekMinMax.start.date),
    dateToCubeQueryDateString(weekMinMax.end.date),
  ];
  return dateRange;
};

export const getTSColWeekRange = (weekNumMin: number, weekColQty: number) => {
  const weekMinMax = getTSColWeekMinMax(weekNumMin, weekColQty);
  return eachWeekOfInterval(
    { start: weekMinMax.start.date, end: weekMinMax.end.date },
    { weekStartsOn: DNS_FNS_MONDAY },
  ).map((week, index) => {
    return {
      date: week,
      num: weekMinMax.start.num + index,
    };
  });
};

export const formatDateForChart = (date: Date, granularity: TimeDimensionGranularity) => {
  if (granularity === "week") {
    return format(date, StandardDateFormat.MONTHDAY);
  } else if (granularity === "month") {
    return format(date, StandardDateFormat.MONTH);
  } else {
    throw Error("Unsupported granularity for chart date format");
  }
};

export const formatDateRangeAsIntervalString = (dateRange: [Date, Date], granularity: TimeDimensionGranularity) => {
  // "n" month(s)/weeks(s)

  const [startDate, endDate] = dateRange;

  if (!isValid(startDate) || !isValid(endDate)) {
    throw new Error("Invalid dates provided");
  }

  // Calculate the difference based on the granularity
  let diff;
  if (granularity === "week") {
    diff = differenceInWeeks(endDate, startDate);
  } else if (granularity === "month") {
    diff = differenceInMonths(endDate, startDate);
  } else {
    throw new Error('Unsupported granularity. Use "week" or "month".');
  }

  return `${diff + 1} ${granularity}${diff > 0 ? "s" : ""}`;
};
