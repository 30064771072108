import { CAWorkflowExecution, Role } from "./changeApprovals/types";

export const getLastApprovedVersionByRole = (workflow: CAWorkflowExecution, role: Role) => {
  // Obvious cases
  if (workflow.state.approval_status.startsWith("APPROVED")) {
    // Everyone approved = most recent version
    return workflow.state.version_history[0];
  } else if (workflow.state.approval_status.startsWith("CANCELLED")) {
    // Cancelled = original version
    return workflow.state.version_history[workflow.state.version_history.length - 1];
  }

  // Find user id for role
  if (role === Role.CONTROL_TOWER) {
    return undefined;
  }
  const roleApproverIndex = role === Role.DEMAND_PLANNER ? 0 : workflow.state.approvers.length - 1;
  const approver = workflow.state.approvers[roleApproverIndex];
  if (!approver) return undefined;

  const roleUserId = approver.user.id;

  // Iterate over event history and find latest event where this userId "approved" (EDIT OR APPROVE)
  // Version created by this event, or most recently from this event is latest approved version
  // Both events and versions are ordered by descending date
  const eventHistory = workflow.state.event_history;
  const versionHistory = workflow.state.version_history;
  const lastApprovedEvent = eventHistory.find(
    (event) =>
      (event.data.triggered_by.id === roleUserId ||
        (event.data.triggered_by.id === "andrew+andros@eqiltd.com" && role === Role.DEMAND_PLANNER)) &&
      (event.data.workflow_event_type === "EDIT" ||
        event.data.workflow_event_type === "APPROVE" ||
        event.data.workflow_event_type === "CREATE"),
  );

  if (!lastApprovedEvent) {
    return undefined;
  }
  const lastApprovedVersion = versionHistory.find((version) => version.created_by.id === lastApprovedEvent?.id);
  return lastApprovedVersion;
};
