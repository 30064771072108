import {
  IdentityUser,
  ShippingMethodEnum,
  SourceMetadata,
  StateType,
  User,
  WorkflowEventData,
  WorkflowEventRequestData,
  WorkflowExecution,
  WorkflowExecutionSearchAttributes,
  WorkflowState,
} from "../../../api/types";

export enum EventTypeEnum {
  CREATE = "CREATE",
  EDIT = "EDIT",
  APPROVE = "APPROVE",
  CANCEL = "CANCEL",
  START = "START",
  SYNC_COMPLETE = "SYNC_COMPLETE",
  SYNC_FAILED = "SYNC_FAILED",
}

export enum Role {
  DEMAND_PLANNER = "DEMAND_PLANNER",
  CONTROL_TOWER = "CONTROL_TOWER",
  PCL = "PCL",
}

export type EventType = `${EventTypeEnum}`;

export type Approver = {
  role: string;
  user: User;
  approved: boolean;
};

export type WorkflowTypeName = "ChangeApprovalsWorkflow";
export type WorkflowTypeVersion = "1.0";

export enum ApprovalStatusEnum {
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
  CANCELLED = "CANCELLED",
  CANCELLED__SYNC_CONFLICT = "CANCELLED__SYNC_CONFLICT",
  APPROVED__SYNC_READY = "APPROVED__SYNC_READY",
  APPROVED__SYNC_STARTED = "APPROVED__SYNC_STARTED",
  APPROVED__SYNC_COMPLETE = "APPROVED__SYNC_COMPLETE",
  APPROVED__SYNC_FAILED = "APPROVED__SYNC_FAILED",
}

export type ApprovalStatus = `${ApprovalStatusEnum}`;

export type StateTransition = {
  id?: number;
  state_type: `${StateType}`;
  to_state_id: number;
  at: Date;
  actual: boolean;
};

export interface Row {
  created_row_id_hash?: string;
  source_row_id_hash?: string;
  state_transitions: StateTransition[];
  source_metadata: Partial<SourceMetadata>;
  quantity: number;
  shipping_method: `${ShippingMethodEnum}`;
}

export interface RowGroup {
  company_id: number;
  item_id: number;
  source_row_name: string;
  rows: Row[];
}

export interface Event {
  time: string;
  id: string;
}

export interface Version {
  created_by: Event;
  data: RowGroup;
}

export interface CAWorkflowEventData extends WorkflowEventData {
  workflow_type_name: WorkflowTypeName;
  workflow_event_type: EventType;
}

export interface CAWorkflowEventRequestData extends WorkflowEventRequestData {
  workflow_type_name: WorkflowTypeName;
  workflow_event_type: EventType;
  data?: RowGroup;
}

export interface CAWorkflowEvent {
  id: string;
  data: CAWorkflowEventData;
  time: string;
  source: string | null;
  subject: string | null;
}

export interface CAWorkflowState extends WorkflowState {
  approvers: Approver[];
  event_history: CAWorkflowEvent[];
  approval_status: ApprovalStatus;
  version_history: Version[];
}

export interface CAWorkflowExecutionSearchAttributes extends WorkflowExecutionSearchAttributes {
  approvers: Approver[];
  approval_status: ApprovalStatus;
  current_approver: IdentityUser | null;
  vendor_country: string;
}

export interface CAWorkflowExecution extends WorkflowExecution {
  state: CAWorkflowState;
  search_attributes: CAWorkflowExecutionSearchAttributes;
}
