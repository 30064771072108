import { useQuery } from "@tanstack/react-query";
import { GetStatesParams, sopApiClient } from "../../api/client";
import { State } from "../../api/types";

export const useFetchStates = <T extends State[]>(params?: GetStatesParams) => {
  return useQuery<T>({
    queryKey: ["states", params?.state_type],
    queryFn: async () => {
      const response = await sopApiClient.states.getAll<T>(params);
      if (!response.success) throw new Error(response.message);
      const results: T = response.data as T;
      if (results && results.length > 0) {
        results.sort((a, b) => {
          const stateComp = a.state_type.localeCompare(b.state_type);
          if (stateComp !== 0) return stateComp;
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      }
      return results;
    },
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
